angular.module('cmiParts')
.controller('sidebarCourseware', ['$scope', 'dataFactory', 'sidebarFactory', 'clipboard', function($scope, dataFactory, sidebarFactory, clipboard) {
  $scope.slides = sidebarFactory.sidebarData.slides;

  $scope.controller = sidebarFactory.sidebarData.controller;

  $scope.course = sidebarFactory.sidebarData.controller.course;

  $scope.getImages = function() {
    dataFactory.getCourseImages($scope.controller.course.id).success(function(response) {
      $scope.files = response.data.images;
    });
  };
  $scope.unpublish = function() {
    $scope.controller.unpublishCourse();
  };
  $scope.publish = function() {
    $scope.controller.publishCourse();
  };
  $scope.copyToClipboard = function(file) {
    clipboard.copyText('![img](' + file + ')');
  };
  $scope.doUploadNewFile = function() {
    dataFactory.attachFileToCourse($scope.controller.course.id, $scope.uploadNewFileToCourse).success(function(response) {
      $scope.getImages();
    });
  };
  $scope.addSlide = function(template) {
    $scope.controller.addSlide(template);
    sidebarFactory.closeSidebar();
  };

  $scope.getImages();
}]);
