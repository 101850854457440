angular.module('cmiParts')
.controller('profileController', ['$scope', '$state', 'dataFactory', '$element', function($scope, $state, dataFactory, $element) {
  $scope.dataFactory = dataFactory;
  dataFactory.getProfile().success(function(response) {
    $scope.user = response.data;
  });

  $scope.startAvatarUpload = function() {
    $element.find('input[type="file"]').trigger('click');
  };

  $scope.$watch(function() {
    return $scope.newAvatar;
  }, function(newVal, oldVal) {
    if(newVal !== undefined) {
      dataFactory.uploadAvatar(newVal);
    }
  });
}]);
