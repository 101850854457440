angular.module('cmiParts')
.directive('cmiTodos', function() {
  return {
    'templateUrl': 'dist/templates/widgets/todo.html',
    scope: {
      'id': '@',
      'type' : '@'
    },
    controller: ['dataFactory', '$scope', '$attrs', '$timeout',  function(dataFactory, $scope, $attrs, $timeout) {
      $scope.getToDos = function() {
        dataFactory.getToDos().success(function(response) {
          $scope.todos = response.data.data;
        });
      };

      $scope.newToDo = function() {
        dataFactory.putToDo($scope.todo, $scope.id, $scope.type).success(function(response) {
          $scope.todo = '';
          $scope.getToDos();
        }); //todo: success
      };

      $scope.deleteToDo = function(id, index) {
        $scope.todos[index].done = true;
        dataFactory.deleteToDo(id).success(function(response) {
          $scope.getToDos();
        });
      };
      $scope.getToDos();
    }]
  };
});
