angular.module('cmiParts')
.controller('notificationsController', ['$scope', 'dataFactory', function($scope, dataFactory) {
  $scope.currentPage = 1;
  $scope.getNotifications = function() {
    return dataFactory.getNotifications($scope.currentPage).success(function(response) {
      $scope.notifications = response.data;
    });
  };

  $scope.markAllRead = function() {
    $scope.markingAllRead = true;
    dataFactory.markAllRead().success(function(response) {
      $scope.getNotifications().success(function() {
        delete $scope.markingAllRead;
      });
    });
  };
  $scope.templateUrl = function(notification) {
    if(notification != null && notification.message != undefined) {
      return 'dist/templates/notification-page/' + notification.message + '.html';
    }
  }
  $scope.$watch('currentPage', function(newVal) {
    $scope.getNotifications();
  });
  $scope.toggleNotificationStatus = function(id, status) {
    if(status == 1) {
      dataFactory.deleteReadStatus(id).success(function(response) {
        $scope.getNotifications();
      });
    } else {
      dataFactory.markReadStatus(id).success(function(response) {
        $scope.getNotifications();
      });
    }
  };

  $scope.getNotifications();
}]);
