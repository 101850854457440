angular.module('cmiParts')
.directive('cmiTag', function() {
  return {
    'templateUrl': 'dist/templates/directives/cmi-tag.html',
    scope: {
      'tags_string': '@tags'
    },
    controller: ['$scope', '$attrs', function($scope, $attrs) {
      $attrs.$observe('tags', function(val) {
        $scope.parseTags(val);
      });
      $scope.parseTags = function(val) {
        var colors = {
          'in-process':'label-warning',
          'released':'label-success',
          'in-review':'label-info'
        };
        var tags = val.split(' ');
        for (var i = 0; i < tags.length; i++) {
          tags[i] = {
            name: tags[i],
            color: colors[tags[i]]
          };
        }
        $scope.tags = tags;
      };
    }]
  };
});
