angular.module('cmiParts')
.config(['$httpProvider', function($httpProvider) {
  $httpProvider.interceptors.push(['$q', '$location', '$window', '$injector', function($q, $location, $window, $injector) {
    var ngNotify = null;
    var getNotify = function() {
      if(!ngNotify) ngNotify = $injector.get('ngNotify');
      return ngNotify;
    };
    return {
      'responseError': function (response) {
        if(response.status == 403 && response.data.error == '')
          response.data.error = 'Unauthorized';

        if(typeof response.data.uuid != 'undefined') {
          console.log('Error thrown. UUID: '  + response.data.uuid);
        }
        var notify = response.data.error;
        if(typeof response.data.error == 'object') {
          notify = '';
          for (k in response.data.error) {
            notify += ' ' + k.charAt(0).toUpperCase() + k.slice(1) + ': ' + response.data.error[k];
          }
        }
        getNotify().set(notify, 'warn');
        return $q.reject(response);
      }
    };
  }]);
}])
.factory('dataFactory', ['$http', function($http) {

  var urlBase = 'api/v1';
  var dataFactory = {};
  dataFactory.user = [];

  dataFactory.archivePart = function(part, archive) {
    return $http.put(urlBase + '/part/' + part + '/archive', {archive: archive});
  };
  
  dataFactory.patchPoComments = function(po, comments) {
    return $http.patch(urlBase + '/pos/' + po, {comments: comments});
  };
  dataFactory.patchPoTerms = function(po, terms) {
    return $http.patch(urlBase + '/pos/' + po, {terms: terms});
  };
  dataFactory.getCourseImages = function(course) {
    return $http.get(urlBase + '/course/' + course + '/images');
  };
  dataFactory.attachFileToCourse = function(course, file) {
    return $http({
      url: urlBase + '/course/' + course + '/image',
      method: "POST",
      headers: { 'Content-Type': undefined },
      transformRequest: function () {
        var formData = new FormData();
        formData.append("attachment", file);
        formData.append('message', 'test');
        return formData;
      }
    });
  };
  dataFactory.deleteFabStep = function(part, step) {
    return $http.delete(urlBase + '/part/' + part + '/step/' + step);
  };
  dataFactory.randomPassword = function(length) {
    return $http.get(urlBase + '/password/random', {params: {length: length}});
  };
  dataFactory.searchPasswords = function(value) {
    return $http.get(urlBase + '/passwords/search', {params: {search: value}});
  };
  dataFactory.patchPassword = function(password, value) {
    return $http.patch(urlBase + '/password/' + password, value);
  };
  dataFactory.getPassword = function(password) {
    return $http.get(urlBase + '/password/' + password);
  };
  dataFactory.decryptPassword = function(password) {
    return $http.get(urlBase + '/password/' + password + '/decrypt');
  };
  dataFactory.createPassword = function(password) {
    return $http.put(urlBase + '/passwords', password);
  };
  dataFactory.getPasswords = function(page) {
    return $http.get(urlBase + '/passwords', {params: {page: page}});
  };
  dataFactory.createCourseSlide = function(course, content, title, template) {
    return $http.put(urlBase + '/course/' + course + '/slide', {content: content, title: title, template: template});
  };
  dataFactory.saveCourseSlide = function(course, slide, content) {
    return $http.patch(urlBase + '/course/' + course + '/slide/' + slide, {content: content});
  };
  dataFactory.publishCourse = function(course) {
    return $http.put(urlBase + '/course/' + course + '/publish');
  };
  dataFactory.unpublishCourse = function(course) {
    return $http.delete(urlBase + '/course/' + course + '/publish');
  };
  dataFactory.createCourse = function(course) {
    return $http.put(urlBase + '/course', course);
  };
  dataFactory.getUnpublishedCourses = function() {
    return $http.get(urlBase + '/courses/unpublished');
  };
  dataFactory.getCourses = function() {
    return $http.get(urlBase + '/courses');
  };

  dataFactory.getCourse = function(course) {
    return $http.get(urlBase + '/course/' + course);
  };

  dataFactory.getCourseTags = function() {
    return $http.get(urlBase + '/courses/tags');
  };
  dataFactory.confirmAccount = function(token) {
    return $http.post(urlBase + '/confirm/' + token);
  };
  dataFactory.register = function(credentials) {
    return $http.post(urlBase + '/auth/register', credentials);
  };
  dataFactory.getPOs = function() {
    return $http.get(urlBase + '/pos');
  };
  dataFactory.generatePO = function(po) {
    return $http.get(urlBase + '/pos/' + po + '/download');
  };
  dataFactory.patchPO = function(po, data) {
    return $http.patch(urlBase + '/pos/' + po, data);
  };
  dataFactory.saveProjectNotebook = function(project, notebook) {
    return $http.put(urlBase + '/project/' + project + '/notebook', {value: notebook});
  };
  dataFactory.getProjectNotebookHistory = function(project) {
    return $http.get(urlBase + '/project/' + project + '/notebook/history');
  };
  dataFactory.isLoggedIn = function() {
    return $http.get(urlBase + '/auth');
  };
  dataFactory.deleteReadStatus = function(id) {
    return $http.delete(urlBase + '/me/notifications/' + id + '/read');
  };
  dataFactory.markAllRead = function() {
    return $http.post(urlBase + '/me/notifications/read');
  };
  dataFactory.markReadStatus = function(id) {
    return $http.post(urlBase + '/me/notifications/' + id + '/read');
  };
  dataFactory.deleteLineItem = function(po, line_item) {
    return $http.delete(urlBase + '/pos/' + po + '/line-item/' + line_item);
  };
  dataFactory.createPO = function(vendor) {
    return $http.put(urlBase + '/pos', {vendor_id: vendor});
  };
  dataFactory.addLineItem = function(po, data) {
    return $http.put(urlBase + '/pos/' + po + '/line-item', data);
  };
  dataFactory.getPurchaseOrder = function(po) {
    return $http.get(urlBase + '/pos/' + po);
  };

  dataFactory.getVendorProfile = function(vendor) {
    return $http.get(urlBase + '/vendors/' + vendor);
  };
  dataFactory.createVendor = function(data) {
    return $http.put(urlBase + '/vendors', data);
  };
  dataFactory.signOffPart = function(part, role, signoff, comments) {
    return $http.put(urlBase + '/part/' + part + '/signoff', {
      role: role,
      approval: signoff,
      comments: comments
    });
  };
  dataFactory.getVendors = function(page) {
    return $http.get(urlBase + '/vendors', {params: {page: page}});
  };
  dataFactory.reorderSteps = function(steps) {
    return $http.put(urlBase + '/steps/reorder', {steps: steps});
  };
  dataFactory.addFabStep = function(part, step) {
    return $http.put(urlBase + '/part/' + part + '/steps', step);
  };
  dataFactory.editPartDetails = function(part, data) {
    return $http.post(urlBase + '/part/' + part, data);
  };
  dataFactory.getEndpoint = function(endpoint, page) {
    return $http.get(urlBase + '/' + endpoint, {params: {page: page}});
  };
  dataFactory.getDefaultDashboards = function() {
    return $http.get(urlBase + '/dashboards');
  };
  dataFactory.getToDos = function() {
    return $http.get(urlBase + '/me/todos');
  };
  dataFactory.putToDo = function(todo, type, id) {
    return $http.put(urlBase + '/me/todos', {todo: todo, type: type, id: id});
  };

  dataFactory.deleteToDo = function(id) {
    return $http.delete(urlBase + '/me/todos/' + id);
  };
  dataFactory.getRouterParts = function() {
    return $http.get(urlBase + '/parts/status/router');
  };
  dataFactory.getUserSubscriptions = function() {
    return $http.get(urlBase + '/me/subscriptions');
  };
  dataFactory.logout = function() {
    return $http.post(urlBase + '/auth/logout');
  };
  dataFactory.getProfile = function () {
    return $http.get(urlBase + '/me').success(function(response) {
      dataFactory.user = response.data;
    });
  };

  dataFactory.getUserProfile = function(user) {
    return $http.get(urlBase + '/user/' + user);
  };

  dataFactory.getPublicUsers = function() {
    return $http.get(urlBase + '/users');
  };
  dataFactory.getClients = function() {
    return $http.get(urlBase + '/clients');
  };

  dataFactory.updateClient = function(client, data) {
    return $http.patch(urlBase + '/client/' + client, data);
  };

  dataFactory.updateProject = function(id, data) {
    return $http.patch(urlBase + '/project/' + id, data);
  };

  dataFactory.updateQueueStatus = function(part, status) {
    return $http.patch(urlBase + '/part/' + part + '/queue', {queue_status: status});
  };

  dataFactory.uprevPart = function(part) {
    return $http.post(urlBase + '/part/' + part + '/revision');
  };
  dataFactory.getParts = function(page) {
    return $http.get(urlBase + '/parts', {params: {page:page}});
  };
  dataFactory.getProjects = function() {
    return $http.get(urlBase + '/projects');
  };
  dataFactory.getProject = function(id, page) {
    return $http.get(urlBase + '/project/' + id, {params: {page: page}});
  };
  dataFactory.putProject = function(client, name) {
    return $http.put(urlBase + '/client/' + client + '/project', {name: name});
  };
  dataFactory.getPartDetails = function(id) {
    return $http.get(urlBase + '/part/' + id);
  };
  dataFactory.patchPoDueDate = function(po, date) {
    return $http.patch(urlBase + '/pos/' + po, {due: date});
  };
  dataFactory.searchDatabase = function(searchTerms) {
    return $http.get(urlBase + '/search', {params: {search: searchTerms}});
  };
  dataFactory.getPartTimeline = function(id) {
    return $http.get(urlBase + '/part/' + id + '/events');
  };

  dataFactory.getUnreadNotifications = function() {
    return $http.get(urlBase + '/me/notifications/unread');
  };

  dataFactory.getNotifications = function(page) {
    return $http.get(urlBase + '/me/notifications', {params: {page: page}});
  };
  dataFactory.checkLogin = function($credentials) {
    return $http.post(urlBase + '/auth', $credentials);
  };

  dataFactory.addRole = function(uuid, role) {
    return $http.put(urlBase + '/admin/user/' + uuid + '/role/' + role);
  };
  dataFactory.removeRole = function(uuid, role) {
    return $http.delete(urlBase + '/admin/user/' + uuid + '/role/' + role);
  };
  dataFactory.getRoles = function() {
    return $http.get(urlBase + '/admin/roles');
  };
  dataFactory.getUsers = function() {
    return $http.get(urlBase + '/admin/users');
  };

  dataFactory.getCustomer = function (id) {
    return $http.get(urlBase + '/' + id);
  };

  dataFactory.insertCustomer = function (cust) {
    return $http.post(urlBase, cust);
  };

  dataFactory.updateCustomer = function (cust) {
    return $http.put(urlBase + '/' + cust.ID, cust)
  };

  dataFactory.deleteCustomer = function (id) {
    return $http.delete(urlBase + '/' + id);
  };

  dataFactory.getOrders = function (id) {
    return $http.get(urlBase + '/' + id + '/orders');
  };
  dataFactory.getClientDetails = function(id) {
    return $http.get(urlBase + '/client/'  + id);
  };

  dataFactory.changePassword = function(password, token) {
    return $http.put(urlBase + '/user/' + token + '/password', password);
  };
  dataFactory.resetPassword = function(email) {
    return $http.delete(urlBase + '/user/' + email + '/password');
  };
  dataFactory.createClient = function(client, code) {
    return $http.put(urlBase + '/client', {name: client, code: code});
  };
  dataFactory.uploadAvatar = function(file) {
    dataFactory.user.avatar = '';
    return $http({
      url: urlBase + '/me/avatar',
      method: "POST",
      headers: { 'Content-Type': undefined },
      transformRequest: function (data) {
        var formData = new FormData();
        formData.append("avatar", file);
        return formData;
      }
    }).success(function(response) {
      dataFactory.user.avatar = response.data + '?' + new Date().getTime();
    });
  };

  dataFactory.createPart = function(part) {
    return $http.put(urlBase + '/part', part);
  };
  dataFactory.changePartStatus = function(part, status) {
    return $http.post(urlBase + '/part/' + part, {status_id: status});
  };

  dataFactory.deleteAttachment = function(part, attachment) {
    return $http.delete(urlBase + '/part/' + part + '/attachment/' + attachment);
  };
  dataFactory.attachFile = function(part, file, type, name) {
    return $http({
      url: urlBase + '/part/' + part + '/attachment',
      method: "POST",
      headers: { 'Content-Type': undefined },
      transformRequest: function (data) {
        var formData = new FormData();
        formData.append('type', type);
        formData.append('description', name);
        formData.append("attachment", data.files);
        return formData;
      },
      data: { model: {'type': type, description: name}, files: file }
    });
  }
  return dataFactory;
}]);
