angular.module('cmiParts')
.controller('sidebarNewPassword', ['sidebarFactory', '$scope', '$state', 'dataFactory', function(sidebarFactory, $scope, $state, dataFactory) {
  $scope.submitNewPassword = function() {
    dataFactory.createPassword($scope.newPassword).success(function(response) {
      $state.reload();
    });
  };

  $scope.newPassword = {};
  $scope.randomPassword = function() {
    dataFactory.randomPassword($scope.randomLength).success(function(response) {
      $scope.newPassword.password = response.data;
    });
  };
}]);
