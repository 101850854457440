angular.module('cmiParts')
.controller('passwordsController', ['$state', '$scope', 'dataFactory', 'sidebarFactory', '$timeout', 'clipboard', function($state, $scope, dataFactory, sidebarFactory, $timeout, clipboard) {
  $scope.addPassword = function() {
    sidebarFactory.openSidebar({
      templateUrl: 'dist/templates/sidebars/newPassword.html',
      title: 'Adding Password'
    });
  };

  $scope.getPasswords = function() {
    dataFactory.getPasswords($scope.currentPage).success(function(response) {
      $scope.passwords = response.data;
    });
  };

  $scope.editPassword = function(id) {
    sidebarFactory.openSidebar({
      templateUrl: 'dist/templates/sidebars/editPassword.html',
      title: 'Editing Password for',
      data: {password: id}
    });
  };

  $scope.hidePassword = function(id) {
    $scope.passwords.data[id].password = undefined;
  };

  $scope.$watch('currentPage', function(newVal) {
    if(newVal !== undefined) {
      $scope.getPasswords();
    }
  });
  $scope.decryptPassword = function(password, id) {
    $scope.clearPasswords();

    dataFactory.decryptPassword(password).success(function(response) {
      $scope.passwords.data[id].password = response.data;
      $scope.clearTimer = $timeout($scope.clearPasswords, 5000);
    });
  };

  $scope.copyPassword = function(password) {
    clipboard.copyText(password);
    $scope.copied = true;
    $scope.successTimer = $timeout(function() {
      $timeout.cancel($scope.successTimer);
      $scope.copied = false;
    }, 1000);
  };

  $scope.clearPasswords = function() {
    $timeout.cancel($scope.clearTimer);
    for(var i = 0; i < $scope.passwords.data.length; i++) {
      $scope.passwords.data[i].password = '';
    }
  };

  $scope.$watch('searchPassword', function(newVal) {
    if(newVal !== undefined && newVal.length > 2) {
      dataFactory.searchPasswords(newVal).success(function(response) {
        $scope.passwords = response.data;
      });
    } else {
      $scope.getPasswords();
    }
  });
  $scope.currentPage = 1;
}]);
