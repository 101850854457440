angular.module('cmiParts')
.directive('cmiQueueTag', function() {
  return {
    'templateUrl': 'dist/templates/directives/cmi-queue-tag.html',
    scope: {
      queue: '@'
    },
    controller: ['dataFactory', '$scope', '$element', function(dataFactory, $scope, $element) {
      $scope.queueStatusArray = {
        'zero': '',
        'not_queued': 'Not Queued',
        'router': 'Router',
        'waterjet': 'Waterjet',
        'laser': 'Laser',
        'metal': 'Metal Shop',
        'wood' : 'Wood Shop',
        'bm' : 'Ball Machines',
        'assembly' : 'Assembly'
      };
    }]
  };
});
