angular.module('cmiParts')
.directive('cmiDashWidget', function() {
  return {
    templateUrl: 'dist/templates/widgets/dash_widget.html',
    scope: {
      'endpoint': '@',
      'title' : '@'
    },
    controller: ['dataFactory', '$scope', '$interval', 'sidebarFactory', function(dataFactory, $scope, $interval, sidebarFactory) {

      $scope.templateUrl = function() {
        return 'dist/templates/widgets/' + $scope.endpoint.split('/')[0] + '_widget.html';
      };

      $scope.currentPage = 1;
      $scope.$watch(function() {
        return $scope.currentPage;
      }, function(newVal, oldVal) {
        if(newVal !== undefined && newVal != oldVal) {
          $scope.getEndpoint();
        }
      });

      $scope.getEndpoint = function() {
        dataFactory.getEndpoint($scope.endpoint, $scope.currentPage).success(function(response) {
          $scope.items = response.data;
        });
      }

      $scope.showPartSidebar = function(part) {
        sidebarFactory.openSidebar({
          templateUrl: 'dist/templates/sidebars/partDetails.html',
          title: 'Loading part details..',
          data: {id: part}
        });
      };

      $scope.$on('$destroy', function() {
        $interval.cancel($scope.interval);
      });

      $scope.interval = $interval($scope.getEndpoint, 60000);
      $scope.getEndpoint();
    }]
  };
});
