angular.module('cmiParts')
.controller('clientsController', ['$scope', 'dataFactory', 'sidebarFactory', '$state', function($scope, dataFactory, sidebarFactory, $state) {
  $scope.addClient = function() {
    sidebarFactory.openSidebar({
      templateUrl: 'dist/templates/sidebars/newClient.html',
      title: 'Add new client'
    });
  };
  $scope.showClientDetails = function(id) {
    $state.go('dash.client', {id: id});
  };
  dataFactory.getClients().success(function(response) {
    $scope.clients = response.data;
  });
}]);
