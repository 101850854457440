angular.module('cmiParts')
.controller('forgotPasswordController', ['$state', '$scope', 'dataFactory', function($state, $scope, dataFactory) {
  $scope.companyLogo = 'images/logo.png';

  $scope.sendReminder = function() {
    $scope.loginError = "Please check your email to reset your password";
    dataFactory.resetPassword($scope.forgotReset.email);
  };

}]);
