angular.module('cmiParts')
.directive('clickOff', ['$parse', '$document', function($parse, $document) {
  var dir = {
    compile: function($element, attr) {
      var fn = $parse(attr["clickOff"]);
      console.log(fn);
      console.log(attr['clickOff']);
      return function(scope, element, attr) {
        angular.element($document[0].body).bind("click",                                                                 function(event) {
          scope.$apply(function() {
            fn(scope, {$event:event});
          });
        });
      };
    }
  };
  return dir;
}]);
