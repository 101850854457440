angular.module('cmiParts')
.controller('purchaseOrderDetails', ['$scope', '$state', 'dataFactory', '$timeout', function($scope, $state, dataFactory, $timeout) {
  $scope.getPO = function() {
    return dataFactory.getPurchaseOrder($state.params.po).success(function(response) {
      response.data.due = new Date(response.data.due);
      $scope.po = response.data;
      $scope.vendor = response.data.vendor;
    });
  }

  $scope.editDueDate = function() {
    $scope.editingDueDate = !$scope.editingDueDate;
  }

  $scope.generatePO = function() {
    $scope.generatingPo = true;
    dataFactory.generatePO($scope.po.id).success(function(response) {
      $scope.getPO().success(function(response) {
        $scope.generatingPo = false;
      });
    });
  };
  $scope.closePO = function() {
    dataFactory.patchPO($scope.po.id, {open: false}).success(function(response) {
      $scope.getPO();
    });
  };

  $scope.changedDatePicker = function() {
    dataFactory.patchPoDueDate($scope.po.id, $scope.po.due).success(function(response) {
      $scope.getPO();
    });
  }

  $scope.deleteLineItem = function(id) {
    dataFactory.deleteLineItem($scope.po.id, id).success(function(response) {
      $scope.getPO();
    });
  };

  $scope.savePoTerms = function() {
    $scope.savingTerms = true;
    if($scope.savingTermsTimeout != undefined) {
      $timeout.cancel($scope.savingTermsTimeout);
    }
    dataFactory.patchPoTerms($scope.po.id, $scope.po.terms).success(function(response) {
      $scope.getPO();
      $scope.savedTerms = true;
      delete($scope.savingTerms);
      $scope.savingTermsTimeout = $timeout(function() {
        delete $scope.savedTerms;
      }, 3000);
    });
  };
  $scope.saveComments = function() {
    $scope.savingComments = true;
    dataFactory.patchPoComments($scope.po.id, $scope.po.comments).success(function(response) {
      $scope.getPO();
      delete($scope.savingComments);
      $scope.savedComments = true;
      $timeout(function() {
        delete $scope.savedComments;
      }, 3000);
    });
  };

  $scope.selectPart = function(part, part_number, description) {
    if($scope.newLineItem.data == undefined)
      $scope.newLineItem.data = {};

    $scope.newLineItem.data.part_id = part;
    $scope.newLineItem.data.comment = description;
    $scope.searchPart = part_number;
    $scope.searching = false;
    $scope.clickedPart = true;
  };

  $scope.searching = false;
  $scope.$watch(function() {
    return $scope.searchPart;
  }, function(newVal, oldVal) {
    if(typeof newVal !== 'undefined' && typeof oldVal !== 'undefined' && $scope.clickedPart == false) {
      $scope.searching = (newVal.length > 3 && oldVal.length > 2);
    } else {
      $scope.clickedPart = false;
      $scope.searching = false;
    }

    if($scope.searching) {
      dataFactory.searchDatabase(newVal).success(function(response) {
        $scope.partSearch = response.data.parts.data;
      });
    }
  });

  $scope.doLineItemSubmit = function() {
    dataFactory.addLineItem($scope.po.id, $scope.newLineItem.data).success(function(response) {
        $scope.newLineItem.data = {};
        $scope.searchPart = '';
        $scope.getPO();
    });
  };

  $scope.getPO();
}]);
