angular.module('cmiParts')
.controller('courseIndexController', ['$state', '$scope', 'dataFactory', 'sidebarFactory', function($state, $scope, dataFactory, sidebarFactory) {

  dataFactory.getCourses().success(function(response) {
    $scope.courses = response.data;
  });

  dataFactory.getUnpublishedCourses().success(function(response) {
    $scope.unpublished = response.data;
  });

  $scope.editCourse = function(course) {
    $state.go('dash.courseEdit', {course: course});
  };
  $scope.takeCourse = function(course) {
    $state.go('dash.courseTake', {course: course});
  };
  $scope.createCourse = function() {
    sidebarFactory.openSidebar({
      title: 'Creating new course',
      templateUrl: 'dist/templates/sidebars/sidebarNewCourse.html'
    });
  };
}]);
