angular.module('cmiParts')
.controller('courseEditController', ['$state', '$scope', 'dataFactory', 'sidebarFactory', function($state, $scope, dataFactory, sidebarFactory) {

  $scope.showingSlide = 1;

  $scope.getCourse = function() {
    dataFactory.getCourse($state.params.course).success(function(response) {
      $scope.course = response.data;
    });
  }
  $scope.getCourse();

  $scope.showPreview = function() {
    $scope.edit = false;
  };
  $scope.showEdit = function() {
    $scope.edit = true;
  };
  $scope.editMarkdown = function(index) {
    $scope.editingMarkdown = index;
  };
  $scope.unpublishCourse = function() {
    dataFactory.unpublishCourse($scope.course.id).success(function(response) {
      $scope.course.published = false;
    });
  };

  $scope.publishCourse = function() {
    dataFactory.publishCourse($scope.course.id).success(function(response) {
      $scope.course.published = true;
    });
  };

  $scope.courseDetails = function() {
    sidebarFactory.openSidebar({
      templateUrl: 'dist/templates/sidebars/coursewareSidebar.html',
      title: 'Course Details',
      data: {slides: $scope.course.slides, controller: this}
    });
  };
  $scope.addSlide = function(slide) {
    $scope.course.slides.push({template: slide});
    $scope.showingSlide = $scope.course.slides.length;
    $scope.showEdit();
  };
  $scope.saveSlide = function(slide) {
    if($scope.course.slides[slide].id == undefined) {
      dataFactory.createCourseSlide($scope.course.id, $scope.course.slides[slide].content, $scope.course.slides[slide].title, $scope.course.slides[slide].template).success(function(response) {
        $scope.course.slides[slide].id = response.data.id;
        $scope.showPreview();
      });
    } else {
      dataFactory.saveCourseSlide($scope.course.id, $scope.course.slides[slide].id, $scope.course.slides[slide].content).success(function(response) {
        $scope.showPreview();
      });
    }
  };
}]);
