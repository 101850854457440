angular.module('cmiParts')
.controller('registerController', ['$state', '$scope', 'dataFactory', function($state, $scope, dataFactory) {
  $scope.companyLogo = 'images/logo.png';
  
  $scope.registerNewUser = function() {
    dataFactory.register($scope.credentials).success(function(response) {
      $scope.registerSuccess = true;
    });
  };
}]);
