angular.module('cmiParts')
.directive('cmiQueue', function() {
  return {
    'templateUrl': 'dist/templates/directives/cmi-queue.html',
    require: 'ngModel',
    controller: ['dataFactory', '$scope', '$element', function(dataFactory, $scope, $element) {
      ngModelCtrl = $element.controller('ngModel');

      $scope.changeQueueStatus = function(index) {
        $scope.part.queue_status = index;
        dataFactory.updateQueueStatus($scope.part.id, index);
      };

      $scope.$watch(function() {
        return ngModelCtrl.$modelValue;
      }, function(newVal) {
        if(newVal !== undefined) {
          $scope.part = newVal;
        }
      });

      $scope.queueStatusArray = {
        'zero': '',
        'not_queued': 'Not Queued',
        'router': 'Router',
        'waterjet': 'Waterjet',
        'laser': 'Laser',
        'metal': 'Metal Shop',
        'wood' : 'Wood Shop',
        'bm' : 'Ball Machines',
        'assembly' : 'Assembly'
      };
    }]
  };
});
