angular.module('cmiParts')
.controller('sidebarNewPart', ['sidebarFactory', '$scope', '$state', 'dataFactory', function(sidebarFactory, $scope, $state, dataFactory) {
  if(sidebarFactory.sidebarData.project !== undefined) {
    $scope.outsideProject = false;
    $scope.newPart = {};
    $scope.newPart.project = sidebarFactory.sidebarData.project;
  } else {
    dataFactory.getProjects().success(function(response) {
      $scope.projects = response.data;
    });
    $scope.outsideProject = true;
  }

  $scope.submitNewPart = function() {
    dataFactory.createPart($scope.newPart).success(function(response) {
      $state.go('dash.part', {part: response.part.full_part_number});
    });
  };
}]);
