angular.module('cmiParts')
.controller('mainController', ['$window', 'ngNotify', '$scope', 'dataFactory', 'sidebarFactory', '$rootScope', function($window, ngNotify, $scope, dataFactory, sidebarFactory, $rootScope) {
  ngNotify.config({
    theme: 'pure',
    position: 'top',
    duration: 2000,
    type: 'info',
    sticky: false,
    button: true,
  });
  $scope.hideSidebar = function() {
    //    sidebarFactory.closeIfOpen();
  };
  $scope.loading = true;

  $window.onbeforeunload = function() {

    if($rootScope.preventNavigation) {
      return 'You have unsaved changes! Do you want to continue?';
    }
  };
  $scope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
    if($rootScope.preventNavigation && !confirm('You have unsaved changes! Do you want to continue?')) {
      event.preventDefault();
    }
    $scope.loading = true;
  });
  $scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
    $scope.loading = false;
    $rootScope.preventNavigation = false;
  });
  $scope.auth = true;
}]);
