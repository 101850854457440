angular.module('cmiParts')
.controller('newPurchaseOrderController', ['$state', '$scope', 'dataFactory', function($state, $scope, dataFactory) {
  dataFactory.getVendorProfile($state.params.vendor).success(function(response) {
    $scope.vendor = response.data;
  });

  $scope.getPO = function() {
    dataFactory.getPurchaseOrder($scope.po.id).success(function(response) {
      $scope.po = response.data;
    });
  };

  $scope.editDueDate = function() {
    $scope.editingDueDate = !$scope.editingDueDate;
  }

  $scope.changedDatePicker = function() {
    dataFactory.patchPoDueDate($scope.po.id, $scope.po.due).success(function(response) {
      $scope.getPO();
    });
  }

  $scope.generatePO = function() {
    dataFactory.generatePO($scope.po.id).success(function(response) {
      $scope.getPO();
    });
  };
  $scope.closePO = function() {
    dataFactory.patchPO($scope.po.id, {open: false}).success(function(response) {
      $scope.getPO();
    });
  };

  $scope.submitNewLineItem = function() {
    if(typeof $scope.po == 'undefined') {
      dataFactory.createPO($state.params.vendor).success(function(response) {
        $scope.po = response.data;
        $scope.doLineItemSubmit();
      });
    } else {
      $scope.doLineItemSubmit();
    }
  };

  $scope.selectPart = function(part, part_number, description) {
    if($scope.newLineItem.data == undefined)
      $scope.newLineItem.data = {};

    $scope.newLineItem.data.part_id = part;
    $scope.newLineItem.data.comment = description;
    $scope.searchPart = part_number;
    $scope.searching = false;
    $scope.clickedPart = true;
  };

  $scope.searching = false;
  $scope.$watch(function() {
    return $scope.searchPart;
  }, function(newVal, oldVal) {
    if(typeof newVal !== 'undefined' && typeof oldVal !== 'undefined' && !$scope.clickedPart) {
      $scope.searching = (newVal.length > 3 && oldVal.length > 2);
    } else {
      $scope.searching = false;
    }

    if($scope.searching) {
      dataFactory.searchDatabase(newVal).success(function(response) {
        $scope.partSearch = response.data.parts.data;
      });
    }
  });

  $scope.deleteLineItem = function(id) {
    dataFactory.deleteLineItem($scope.po.id, id).success(function(response) {
      $scope.getPO();
    });
  };

  $scope.doLineItemSubmit = function() {
    dataFactory.addLineItem($scope.po.id, $scope.newLineItem.data).success(function(response) {
      $scope.searchPart = '';
      $scope.newLineItem.data = {};
      $state.go('dash.purchaseOrder', {po: $scope.po.id});
    });
  };

}]);
