angular.module('cmiParts')
.directive('smartSearch', function() {
  return {
    'templateUrl': 'dist/templates/directives/smart-search.html',
    controller: ['$timeout', '$scope', 'dataFactory', '$state', function($timeout, $scope, dataFactory, $state) {
      $scope.go = function(route, params) {
        $scope.showingResults = false;
        $state.go(route, params);
      };
      $scope.keyPress = function($event) {
        if($event.keyCode == 27) {
          $scope.results = [];
          $scope.showingResults = false;
        }
      }

      $scope.search = function() {
        if($scope.debounce != undefined) {
          $timeout.cancel($scope.debounce);
        }
        if($scope.searchValue != undefined && $scope.searchValue.length > 2) {
          $scope.debounce = $timeout(function() {
            dataFactory.searchDatabase($scope.searchValue).success(function(response) {
              $scope.results = response.data;
              $scope.showingResults = true;
              delete $scope.debounce;
            });
          }, 1000);
        }
      };
      $scope.$watch('searchValue', function(newVal, oldVal) {
        if(newVal === undefined || newVal.length < 3) {
          $scope.results = [];
          $scope.showingResults = false;
          delete($scope.debounce);
          return;
        }

        $scope.search();
      });
    }]
  };
});
