angular.module('cmiParts')
.controller('navbarController', ['$interval', '$rootScope', '$state', '$scope', 'dataFactory', '$document', function($interval, $rootScope, $state, $scope, dataFactory, $document) {
  $scope.dataFactory = dataFactory;
  dataFactory.getProfile()
  .success(function(response) {
    $scope.user = response.data;
  })
  .error(function() {
    $state.go('login');
  });

  $scope.miniNav = false;
  $scope.maxNotificationsInStatusBar = 50;

  $scope.toggleNav = function() {
    $scope.miniNav = !$scope.miniNav;
  };
  $scope.getNotifications = function() {
    dataFactory.getUnreadNotifications().success(function(response) {
      $scope.notifications = response.data;
    });
  }

  $scope.go = function(route, data) {
    $state.go(route, data);
  }

  $scope.getNotifications();
  $scope.interval = $interval($scope.getNotifications, 100000);

  $scope.$on('$destroy', function() {
    $interval.cancel($scope.interval);
  });
  $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, options) {
    $scope.hideSearch();
  });

  $scope.getSearchResults = function(search) {
    return dataFactory.searchDatabase(search).then(function(response) {
      return response.data.data.clients;
    });
  };
  $scope.showSearch = function() {
    $scope.searching = true;
  }
  $scope.hideSearch = function() {
    $scope.searching = false;
  }
  $scope.logout = function() {
    dataFactory.logout().then(function() {
      $state.go('login');
    });
  };
}]);
