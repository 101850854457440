angular.module('cmiParts')
.directive('cmiStatus', function() {
  return {
    'templateUrl': 'dist/templates/directives/cmi-status.html',
    scope: {
      'status': '@status'
    },
    controller: ['$scope', '$attrs', function($scope, $attrs) {
      $attrs.$observe('status', function(val) {
        $scope.parseStatus(val);
      });
      $scope.parseStatus = function(val) {
        val = parseInt(val);
        var colors = [
          '',
          'label-warning',
          'label-info',
          'label-success',
          'label-primary',
          'label-default'
        ];
        var names = [
          '',
          'In Process',
          'In Review',
          'Released',
          'Fabrication',
          'On Order',
          'In Hand'
        ];
        $scope.status = names[val];
        $scope.color = colors[val];
      };
    }]
  };
});
