angular.module('cmiParts')
.directive('cmiStatusProgress', function() {
  return {
    'templateUrl': 'dist/templates/directives/cmi-status-progress.html',
    require: 'ngModel',
    controller: ['dataFactory', '$scope', '$element', function(dataFactory, $scope, $element) {
      ngModelCtrl = $element.controller('ngModel');

      $scope.changePartStatus = function(index) {
        dataFactory.changePartStatus($scope.part.id, index).success(function(response) {
          $scope.part.status_id = index;
          ngModelCtrl.$setViewValue($scope.part);
        });
      };

      $scope.$watch(function() {
        return ngModelCtrl.$modelValue;
      }, function(newVal) {
        if(newVal !== undefined) {
          $scope.part = newVal;
        }
      });

      $scope.partStatus = [
        '',
        'In Process',
        'In Review',
        'Released',
        'Fabrication',
        'On Order',
        'In Hand'
      ];
    }]
  };
});
