angular.module('cmiParts')
.controller('projectNotebookHistoryController', ['$scope', '$state', 'dataFactory', 'sidebarFactory', function($scope, $state, dataFactory, sidebarFactory) {

  $scope.currentPage = 1;

  $scope.getData = function() {
    dataFactory.getProjectNotebookHistory($state.params.project).success(function(response) {
      $scope.project = response.data;
    });
  }

  $scope.getData();
}]);
