angular.module('cmiParts')
.controller('projectsController', ['$state', '$scope', 'dataFactory', 'sidebarFactory', function($state, $scope, dataFactory, sidebarFactory) {
  $scope.$state = $state;
  $scope.addProject = function() {
    sidebarFactory.openSidebar({
      templateUrl: 'dist/templates/sidebars/newProject.html',
      title: 'Add new project'
    });
  };

  $scope.showProjectDetails = function(id) {
    sidebarFactory.openSidebar({
      title: 'Loading project details..',
      templateUrl: 'dist/templates/sidebars/projects.html',
      data: {id: id}
    });
  };

  dataFactory.getProjects().success(function(response) {
    $scope.projects = response.data;
  });
}]);
