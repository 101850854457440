angular.module('cmiParts')
.controller('sidebarNewProject', ['$scope', '$state', 'dataFactory', 'sidebarFactory', function($scope, $state, dataFactory, sidebarFactory) {
  dataFactory.getClients().success(function(response) {
    $scope.clients = response.data;
  });

  if(typeof sidebarFactory.sidebarData.client !== 'undefined') {
    $scope.addingToClient = true;
    $scope.newProject = {};
    $scope.newProject.client = sidebarFactory.sidebarData.client;
  }

  $scope.submitNewProject = function() {
    dataFactory.putProject($scope.newProject.client, $scope.newProject.name).success(function(response) {
      $state.go('dash.project', {project: response.data.id});
    });
  };
}]);
