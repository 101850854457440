angular.module('cmiParts')
.controller('sidebarEditPassword', ['sidebarFactory', '$scope', '$state', 'dataFactory', function(sidebarFactory, $scope, $state, dataFactory) {
  $scope.editPassword = sidebarFactory.sidebarData.password;

  dataFactory.getPassword($scope.editPassword).success(function(response) {
    $scope.password = response.data;
    sidebarFactory.sidebarTitle = 'Editing password for ' + response.data.site;
    $scope.editPassword.username = response.data.username;
  });

  $scope.submitEditPassword = function() {
    dataFactory.patchPassword($scope.password.id, $scope.editPassword).success(function(response) {
    });
  };

  $scope.editPassword = {};
  $scope.randomPassword = function() {
    dataFactory.randomPassword($scope.randomLength).success(function(response) {
      $scope.editPassword.password = response.data;
    });
  };
}]);
