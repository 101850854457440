angular.module('cmiParts')
.factory('sidebarFactory', ['$rootScope', function($rootScope) {
  var sidebarFactory = {};

  sidebarFactory.sidebarOpen = false;
  sidebarFactory.sidebarTemplate = '';
  sidebarFactory.sidebarTitle = '';
  sidebarFactory.sidebarData = {};

  sidebarFactory.setView = function(view) {
    sidebarFactory.sidebarTemplate = view;
  };

  sidebarFactory.closeIfOpen = function() {
    if(sidebarFactory.sidebarOpen) {
      sidebarFactory.closeSidebar();
    }
  };
  $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, options) {
    sidebarFactory.closeSidebar();
  });

  sidebarFactory.openSidebar = function(config) {
    if(config !== undefined) {
      if(config.templateUrl !== undefined) {
        sidebarFactory.sidebarTemplate = config.templateUrl;
      }
      if(config.title !== undefined) {
        sidebarFactory.sidebarTitle = config.title;
      }
      if(config.data !== undefined) {
        sidebarFactory.sidebarData = config.data;
      }
    }

    sidebarFactory.sidebarOpen = true;
  };

  sidebarFactory.closeSidebar = function() {
    sidebarFactory.sidebarOpen = false;
    sidebarFactory.sidebarTemplate = null;
    sidebarFactory.sidebarTitle = null;
    sidebarFactory.sidebarData = {};
  };

  sidebarFactory.toggleSidebar = function() {
    sidebarFactory.sidebarOpen = !sidebarFactory.sidebarOpen;
  };

  return sidebarFactory;
}]);
