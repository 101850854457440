angular.module('cmiParts')
.directive('cmiSignoff', function() {
  return {
    'templateUrl': 'dist/templates/directives/cmi-signoff.html',
    require: 'ngModel',
    controller: ['dataFactory', '$scope', '$element', function(dataFactory, $scope, $element) {
      ngModelCtrl = $element.controller('ngModel');

      $scope.signOffPart = function(part, role, signoff, comment) {
        dataFactory.signOffPart(part, role, signoff, comment).success(function(response) {
          $scope.signedOff = true;
        });
      };

      $scope.submitRejectWithComment = function(part, role, signoff, comment) {
        dataFactory.signOffPart($scope.part.id, $scope.rejectAsRole, false, $scope.rejectWithComment).success(function() {
          $scope.signedOff = false;
          $element.trigger('click');
        });
      };

      $scope.$watch(function() {
        return ngModelCtrl.$modelValue;
      }, function(newVal) {
        if(newVal !== undefined) {
          $scope.part = newVal;

          $scope.signedOff = false;
          if($scope.part.revision != undefined && $scope.part.revision.signoffs != undefined) {
            for (var i = 0; i < $scope.part.revision.signoffs.length; i++) {
              if($scope.part.revision.signoffs[i].user_id == dataFactory.user.id && $scope.part.revision.signoffs[i].approved == '1') {
                $scope.signedOff = true;
              }
            }
          }
        }
      });

      $scope.dataFactory = dataFactory;
    }]
  };
});
