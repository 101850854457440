angular.module('cmiParts')
.controller('partsController', ['$scope', 'dataFactory', 'sidebarFactory', '$state', function($scope, dataFactory, sidebarFactory, $state) {

  $scope.go = function(data) {
    $state.go('dash.part', data);
  };
  $scope.addPart = function() {
    sidebarFactory.openSidebar({
      templateUrl: 'dist/templates/sidebars/newPart.html',
      title: 'Add new part'
    });
  };
  $scope.showPartDetails = function(id) {
    sidebarFactory.openSidebar({
      templateUrl: 'dist/templates/sidebars/partDetails.html',
      title: 'Loading part details..',
      data: {id: id}
    });
  }

  $scope.$watch(function() {
    return $scope.currentPage;
  }, function(newVal, oldVal) {
    if(newVal !== undefined) {
      $scope.getParts();
    }
  });

  $scope.currentPage = 1;
  $scope.getParts = function() {
    dataFactory.getParts($scope.currentPage).success(function(response) {
      $scope.parts = response.data.parts;
    });
  }

  $scope.getParts();
}]);
