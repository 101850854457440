angular.module('cmiParts')
.controller('dashController', ['$scope', '$state', 'dataFactory', function($scope, $state, dataFactory) {
  dataFactory.getRouterParts().success(function(response) {
    $scope.parts = response.data.data;
  });

  $scope.showingDashboard = 0;

  $scope.showDashboard = function(index) {
    $scope.showingDashboard = index;
  };
  dataFactory.getDefaultDashboards().success(function(response) {
    $scope.dashboards = response.data;
  });
  $scope.showPartDetails = function(id) {
    $state.go('dash.part', {part: id});
  };
}]);
