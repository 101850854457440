angular.module('cmiParts')
.controller('confirmAccountController', ['$state', '$scope', 'dataFactory', function($state, $scope, dataFactory) {
  $scope.companyLogo = 'images/logo.png';
  dataFactory.confirmAccount($state.params.token).success(function(response) {
    if(response.message == 'successfully registered') {
      $scope.success = true;
    } else {
      $scope.fail = true;
    }
  }).error(function(response) {
    $scope.fail = true;
  });
}]);