angular.module('cmiParts')
.controller('adminUsersController', ['sidebarFactory', '$scope', '$state', 'dataFactory', function(sidebarFactory, $scope, $state, dataFactory) {
  $scope.getUsers = function() {
    dataFactory.getUsers().success(function(response) {
      $scope.users = response.data;
    });
  };
  
  $scope.addUser = function() {
    sidebarFactory.openSidebar({
      'templateUrl' : 'dist/templates/sidebars/newUser.html',
      'title' : 'Add New User'
    });
  };
  
  $scope.addRole = function(user, role) {
    var uuid = user.uuid;
    
    dataFactory.addRole(uuid, role).success(function(response) {
      $scope.getUsers();
    });
  };
  
  $scope.removeRole = function(user, role) {
    var uuid = user.uuid;
    
    dataFactory.removeRole(uuid, role).success(function(response) {
      $scope.getUsers();
    });
  };
  
  $scope.getUsers();
}]);
