angular.module('cmiParts')
.controller('partDetailsController', ['$scope', '$state', 'dataFactory', 'sidebarFactory', '$interval', function($scope, $state, dataFactory, sidebarFactory, $interval) {
  $scope.uploadAttachment = function() {
    dataFactory.attachFile($scope.part.id, $scope.newAttachment, 2, $scope.attachmentName).success(function() {
      $scope.getPartDetails();
    });
    $scope.attachmentName = '';
    $scope.newAttachment = null;
  };

  $scope.$on('$destroy', function() {
    $interval.cancel($scope.interval);
  });

  $scope.cancelDetailEdit = function(field) {
    $scope[field] = false;
  };
  $scope.updateDetails = function(field) {
    var data = {};
    data[field] = $scope.part[field];
    dataFactory.editPartDetails($scope.part.id, data).success(function(response) {
      $scope.editing = false;
      $scope.getPartDetails();
    });
  };
  
  $scope.archivePart = function(archive) {
    dataFactory.archivePart($scope.part.id, archive).success(function(response) {
      $scope.getPartDetails();
    });
  };

  $scope.addFabStep = function() {
    dataFactory.addFabStep($scope.part.id, {step_description: $scope.newFabStep}).success(function(response) {
      $scope.newFabStep = '';
      $scope.getPartDetails();
    });
  };

  $scope.deleteFabStep = function(step) {
    dataFactory.deleteFabStep($scope.part.id, step).success(function(response) {
      $scope.getPartDetails();
    });
  }
  $scope.editLongDescription = function() {
    $scope.editing = true;
  };
  $scope.getPartDetails = function() {
    return dataFactory.getPartDetails($state.params.part).success(function(response) {
      $scope.part = response.data;
    });
  };

  $scope.uprevPart = function() {
    dataFactory.uprevPart($scope.part.id).success(function(response) {
      $scope.getPartDetails();
    });
  };

  $scope.getTimeline = function() {
    dataFactory.getPartTimeline($state.params.part).success(function(response) {
      $scope.timeline = response.data;
    });
  };

  $scope.uploadRevisionDrawing = function() {
    $scope.uploading = true;
    dataFactory.attachFile($scope.part.id, $scope.newRevisionDrawing, 1, 'Revision ' + $scope.part.revision.revision + ' drawing').success(function() {
      $scope.getPartDetails().success(function() {
        delete $scope.uploading;
      });
    });
  };

  $scope.deleteAttachment = function(attachment) {
    dataFactory.deleteAttachment($scope.part.id, attachment).success(function(response) {
      $scope.getPartDetails();
    });
  };

  $scope.getPartDetails();
  $scope.getTimeline();
  $scope.interval = $interval($scope.getTimeline, 15000);

  $scope.reOrder = function($index) {
    $scope.part.revision.fab_steps.splice($index, 1);
    dataFactory.reorderSteps($scope.part.revision.fab_steps).success(function(response) {
      //        $scope.getPartDetails();
    });

  };

  $scope.dragControlListeners = {
    containerPositioning: 'relative'
  };
}]);
