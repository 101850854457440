angular.module('cmiParts')
.controller('clientDetailsController', ['$state', '$scope', 'dataFactory', 'sidebarFactory', function($state, $scope, dataFactory, sidebarFactory) {
  $scope.$state = $state;

  $scope.editing = false;

  dataFactory.getClientDetails($state.params.id).success(function(response) {
    $scope.client = response.data;
  });

  $scope.editClient = function() {
    $scope.editing = true;
  };

  $scope.updateClientName = function() {
    dataFactory.updateClient($state.params.id, {name: $scope.client.name})
    .finally(function(r) {
      $scope.editing = false;
    });
  };

  $scope.addProject = function() {
    sidebarFactory.openSidebar({
      templateUrl: 'dist/templates/sidebars/newProject.html',
      title: 'Add new project',
      data: {client: $scope.client.id}
    });
  };
}]);
