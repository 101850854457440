angular.module('cmiParts')
.controller('vendorsController', ['$scope', '$state', 'dataFactory', 'sidebarFactory', function($scope, $state, dataFactory, sidebarFactory) {
  $scope.getVendors = function() {
    dataFactory.getVendors($scope.currentPage).success(function(response) {
      $scope.vendors = response.data;
    });
  }

  $scope.showVendorDetails = function(vendor) {
    $state.go('dash.vendorProfile', {vendor: vendor});
  };
  $scope.addNewVendor = function() {
    sidebarFactory.openSidebar({
      templateUrl: 'dist/templates/sidebars/newVendor.html',
      title: 'Add new vendor'
    });
  };

  $scope.$watch(function() {
    return $scope.currentPage;
  }, function(newVal, oldVal) {
    if(newVal !== undefined) {
      $scope.getVendors();
    }
  });

  $scope.currentPage = 1;
  $scope.getVendors();
}]);
