angular.module('cmiParts', ['ui.bootstrap', 'ui.router', 'rzModule', 'relativeDate', 'dndLists', 'templates', 'ngNotify', 'ds.clock', 'angular-clipboard', 'markdown', 'diff-match-patch'])
.config(['$stateProvider', '$urlRouterProvider', 'markdownConfig',
  function($stateProvider, $urlRouterProvider, markdownConfig) {
    $urlRouterProvider.otherwise('/404');
    $urlRouterProvider.when('', '/');
    $stateProvider
      .state('dash', {
        templateUrl: 'dist/templates/snippets/navbar.html',
        controller: 'navbarController'
      })
      .state('doPasswordReset', {
        url: '/do-password-reset/{reset_token}',
        templateUrl: 'dist/templates/pages/doPasswordReset.html',
        controller: 'doPasswordResetController'
      })
      .state('dash.home', {
        url: '/home',
        templateUrl: 'dist/templates/pages/home.html',
        controller : 'dashController'
      })
      .state('dash.clients', {
        url: '/clients',
        templateUrl: 'dist/templates/pages/clients.html',
        controller: 'clientsController'
      })
      .state('logout', {
        url: '/logout',
        controller: 'logoutController'
      })
      .state('register', {
        url: '/register',
        templateUrl: 'dist/templates/pages/register.html',
        controller: 'registerController'
      })
      .state('forgotPassword', {
        url: '/forgot-password',
        templateUrl: 'dist/templates/pages/forgotPassword.html',
        controller: 'forgotPasswordController'
      })
      .state('confirm', {
        url: '/confirm-account/:token',
        templateUrl: 'dist/templates/pages/confirm.html',
        controller: 'confirmAccountController'
      })
      .state('dash.notifications', {
        url: '/notifications',
        templateUrl: 'dist/templates/pages/notifications.html',
        controller: 'notificationsController'
      })
      .state('dash.vendorProfile', {
        url: '/vendors/{vendor}',
        templateUrl: 'dist/templates/pages/vendorProfile.html',
        controller: 'VendorController'
      })
      .state('dash.vendors', {
        url: '/vendors',
        templateUrl: 'dist/templates/pages/vendors.html',
        controller: 'vendorsController'
      })
      .state('dash.profile', {
        url: '/profile',
        templateUrl: 'dist/templates/pages/profile.html',
        controller: 'profileController'
      })
      .state('dash.userprofile', {
        url: '/profile/:user',
        templateUrl: 'dist/templates/pages/userProfile.html',
        controller: 'userProfileController'
      })
      .state('dash.projectNotebookHistory', {
        url: '/project/:project/notebook/history',
        templateUrl: 'dist/templates/pages/projectNotebookHistory.html',
        controller: 'projectNotebookHistoryController'
      })
      .state('dash.passwords', {
        url: '/passwords',
        templateUrl: 'dist/templates/pages/passwords.html',
        controller: 'passwordsController'
      })
      .state('dash.users', {
        url: '/users',
        templateUrl: 'dist/templates/pages/users.html',
        controller: 'publicUsersController'
      })
      .state('dash.projects', {
        url: '/projects',
        templateUrl: 'dist/templates/pages/projects.html',
        controller: 'projectsController'
      })
      .state('dash.parts', {
        url: '/parts',
        templateUrl: 'dist/templates/pages/parts.html',
        controller: 'partsController'
      })
      .state('dash.part', {
        url: '/part/:part',
        templateUrl: 'dist/templates/pages/partDetails.html',
        controller: 'partDetailsController'
      })
      .state('dash.purchaseOrders', {
        url: '/purchase-orders',
        templateUrl: 'dist/templates/pages/purchaseOrders.html',
        controller: 'purchaseOrdersController'
      })
      .state('dash.purchaseOrder', {
        url: '/purchase-order/{po}',
        templateUrl: 'dist/templates/pages/purchaseOrderDetails.html',
        controller: 'purchaseOrderDetails'
      })
      .state('dash.vendorNewPurchaseOrder', {
        url: '/vendors/{vendor}/create-po',
        templateUrl: 'dist/templates/pages/newPurchaseOrder.html',
        controller: 'newPurchaseOrderController'
      })
      .state('dash.project', {
        url: '/project/:project',
        templateUrl: 'dist/templates/pages/projectDetails.html',
        controller: 'projectDetailsController'
      })
      .state('dash.client', {
        url: '/client/:id',
        templateUrl: 'dist/templates/pages/clientDetails.html',
        controller: 'clientDetailsController'
      })
      .state('dash.subscriptions', {
        url: '/subscriptions',
        templateUrl: 'dist/templates/pages/subscriptions.html',
        controller: 'subscriptionsController'
      })
      /*
       * ADMIN ROUTES
       */
      .state('dash.admin', {
        templateUrl: 'dist/templates/pages/admin/admin.html'
      })
      .state('dash.admin.users', {
        url: '/admin/users',
        templateUrl: 'dist/templates/pages/admin/users.html',
        controller: 'adminUsersController'
      })
      .state('login', {
        url: '/',
        templateUrl: 'dist/templates/pages/login.html',
        controller: 'loginController'
      })
      .state('not-found', {
        url: '/404',
        templateUrl: 'dist/templates/pages/404.html',
        controller: 'fourOhFourController'
      })

      /**
       * Courseware routes
       */
      .state('dash.courseIndex', {
        url: '/courses',
        templateUrl: 'dist/templates/pages/courseware/courseIndex.html',
        controller: 'courseIndexController'
      })
      .state('dash.courseTake', {
        url: '/course/:course/take',
        templateUrl: 'dist/templates/pages/courseware/coursePresenter.html',
        controller: 'coursePresenterController'
      })
      .state('dash.courseEdit', {
        url: '/course/:course/edit',
        templateUrl: 'dist/templates/pages/courseware/courseEditor.html',
        controller: 'courseEditController'
      })
    ;

    markdownConfig = {
      showdown: {
        strikethrough: true,
        tasklists: true,
        extensions: [
        ]
      }
    };
}])
;
