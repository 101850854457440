angular.module('cmiParts')
.controller('sidebarPartDetails', ['$scope', '$state', 'dataFactory', 'sidebarFactory', '$rootScope', function($scope, $state, dataFactory, sidebarFactory, $rootScope) {
  $scope.getPartDetails = function() {
    dataFactory.getPartDetails(sidebarFactory.sidebarData.id).success(function(response) {
      $scope.part = response.data;
      sidebarFactory.sidebarTitle = response.data.full_part_number + ' Details';
    });
  };
  $scope.getPartDetails();

  $scope.user = dataFactory.user;
  
  $scope.$watch(function() {
    return sidebarFactory.sidebarData;
  }, function(newVal, oldVal) {
    if(newVal.id !== undefined && oldVal.id !== undefined) {
      $scope.getPartDetails();
    }
  });
  $scope.go = function(data) {
    $state.go('dash.part', data);
  };

  $scope.partStatus = [
    '',
    'Design',
    'Review',
    'Released',
    'Fabrication',
    'On Order',
    'In Hand'
  ];

  $scope.deleteFabStep = function(step) {
    $scope.part.revision.fab_steps.splice(step, 1);
  };

}]);
