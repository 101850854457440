angular.module('cmiParts')
.controller('projectDetailsController', ['$scope', '$state', 'dataFactory', 'sidebarFactory', '$rootScope', '$window', function($scope, $state, dataFactory, sidebarFactory, $rootScope, $window) {

  $scope.currentPage = 1;
  $scope.editing = false;

  $scope.editProject = function() {
    $scope.editing = true;
  };

  $scope.updateProjectName = function() {
    dataFactory.updateProject($state.params.project, {name: $scope.project.name})
    .finally(function(r) {
      $scope.editing = false;
    })
  };

  $scope.getData = function() {
    dataFactory.getProject($state.params.project, $scope.currentPage).success(function(response) {
      $scope.project = response.data.project;
      $scope.parts = response.data.parts;
      $scope.originalNotebook = response.data.project.project_notebook.value;
      $scope.saveButton = false;
    });
  }

  $scope.downloadInHand = function() {
    $window.open('api/v1/project/' + $scope.project.id + '/in-hand');
  };
  $scope.showingDashboard = 1;

  $scope.showDashboard = function(index) {
    $scope.showingDashboard = index;
  };

  $scope.getData();

  $scope.addPart = function() {
    sidebarFactory.openSidebar({
      templateUrl: 'dist/templates/sidebars/newPart.html',
      title: 'Add new part',
      data: {project: $scope.project.id}
    });
  };

  $scope.$watch(function() {
    return $scope.saveButton;
  }, function(newVal, oldVal) {
    $rootScope.preventNavigation = newVal;
  });
  $scope.saveProjectNotebook = function() {
    dataFactory.saveProjectNotebook($scope.project.id, $scope.project.project_notebook.value).success(function(response) {
      $scope.getData();
    });
  };
  $scope.cancelProjectNotebook = function() {
    $scope.project.project_notebook.value = $scope.originalNotebook;
  };
  $scope.$watch(function() {
    if(typeof $scope.project != 'undefined' && typeof $scope.project.project_notebook != 'undefined') {
      return $scope.project.project_notebook.value;
    }
  }, function(newVal, oldVal) {
    if(newVal != $scope.originalNotebook)
      $scope.saveButton = true;
    else
      $scope.saveButton = false;
  });
  $scope.$watch(function() {
    return $scope.currentPage;
  }, function(newVal, oldVal) {
    if(newVal !== undefined) {
      $scope.getData();
    }
  });

  $scope.showPartDetails = function(id) {
    sidebarFactory.openSidebar({
      templateUrl: 'dist/templates/sidebars/partDetails.html',
      title: 'Loading part details..',
      data: {id: id}
    });
  }
}]);
