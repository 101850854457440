angular.module('cmiParts')
.controller('doPasswordResetController', ['$state', '$scope', 'dataFactory', function($state, $scope, dataFactory) {
  $scope.companyLogo = 'images/logo.png';

  $scope.changePassword = function() {
    dataFactory.changePassword($scope.newPassword, $state.params.reset_token).success(function() {
      $scope.loginError = 'Success! Please return home and log in with your new password.';
    });
  };

}]);
