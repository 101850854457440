angular.module('cmiParts')
.controller('loginController', ['$state', '$scope', 'dataFactory', function($state, $scope, dataFactory) {
  dataFactory.isLoggedIn().then(function(response) {
    if(response.data.data.status == true) {
      $state.go('dash.home');
    }
  });
  $scope.companyLogo = 'images/logo.png';
  $scope.checkLogin = function() {
    var res = dataFactory.checkLogin($scope.credentials).success(function(response) {
      $state.go('dash.home');
    })
    .error(function(response) {
      $scope.loginError = response.error;
    });
  };

}]);
