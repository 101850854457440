angular.module('cmiParts')
.directive('cmiSidebar', ['sidebarFactory', function(sidebarFactory) {
  return {
    templateUrl: 'dist/templates/directives/sidebar.html',
    link: function(scope, element, attrs) {
      scope.closeSidebar = function() {
        sidebarFactory.closeSidebar();
      };
      scope.$watch(function() {
        return sidebarFactory.sidebarTitle;
      }, function(newValue) {
        scope.sidebarTitle = newValue;
      });
      scope.$watch(function() {
        return sidebarFactory.sidebarTemplate;
      }, function(newValue) {
        scope.sidebarTemplate = newValue;
      });
      scope.$watch(function() {
        return sidebarFactory.sidebarOpen;
      }, function(newValue) {
        scope.sidebarOpen = newValue;
      });
    }
  };
}]);
